import * as React from "react";
import { Navbar } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";

import logo from "../images/pp.jpg";

// markup
const NavBar = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      sticky="top"
      style={{ background: "#333333", boxShadow: "0px 2px 4px 0px #0000006b" }}
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            alt="Dakar America"
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
          &nbsp;DAKAR AMERICA
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/autos">Autos</Nav.Link>
            <Nav.Link href="/pieces">Pièces</Nav.Link>
            <Nav.Link href="/exportation">Exportation</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/contact">Contactez-nous</Nav.Link>
            <Nav.Link href="/dashboard">Login</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
